import { TypeAnimation } from 'react-type-animation';
import './App.css';
import AppearInView from './Components/Reusable/AppearInView/AppearInView';
import AppearOnScroll from './Components/Reusable/AppearInView/AppearOnScroll';
import Card from './Components/Reusable/Card';

export default function App() {

  let projects = [
    {
      name: 'SkillSyntax',
      role: 'Founder, Solo Developer',
      url: 'https://skillsyntax.com',
      imagePath: '/SkillSyntax.png'
    },
    {
      name: 'danielbanks.dev',
      role: 'Founder, Solo Developer',
      url: 'https://danielbanks.dev',
      imagePath: '/me.png'
    }
  ]

  let socials = [
    {
      name: 'Discord',
      url: '@dannb.',
      imagePath: '/DiscordLogo.png',
    },
    {
      name: 'Email',
      url: 'danielbankscoding@gmail.com',
      imagePath: '/EmailLogo.png',
    }
  ]

  return (
    <main>
      <nav>
          <h5>Daniel Banks</h5>
      </nav>

      <div className='landing' id='landing'>
        <AppearInView>
        <div className='hero'>
          <div className='hero-text'>
            <h1>Hi There,<br/> I'm <span>Daniel Banks</span><br/> <span className='typing'><TypeAnimation
              sequence={[
                'Developer', // Types 'One'
                2000, // Waits 1s
                'Website Developer', // Deletes 'One' and types 'Two'
                2000, // Waits 2s
                'Website Designer', // Types 'Three' without deleting 'Two'
                2000,
                'Software Developer',
                2000,
                () => {},
              ]}
              cursor={true}
              repeat={Infinity}
              style={{ color: "#FFC107" }}
            /></span></h1>
          </div>

          <div className='hero-image'>
            <img src="/me.png" alt="Daniel Banks" />
          </div>
        </div>
      </AppearInView>

      <AppearInView>
        <div className='arrow-container'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line></svg>
        </div>
      </AppearInView>
      </div>

      <div className='about'>
        <AppearOnScroll>
          <div className='about-text'>
            <h1>About</h1>
            <p>
              I am a passionate and self-taught programmer who has been coding since the age of 11. I started my journey in web development using HTML and CSS, and later expanded my skills to other languages and technologies. I am from North East England, but I am open to working remotely or relocating for the right opportunity.
                <br/><br/>
              I love programming and it is my main hobby and passion. I enjoy creating games using the Unity engine and C#, as well as exploring new technologies and frameworks. I am always eager to learn more and improve my skills, and I welcome feedback and constructive criticism. I am a team player who can collaborate effectively with others, as well as work independently and take initiative. I have a portfolio of projects that showcase my abilities and creativity, which you can check out on my website. I am looking for a challenging and rewarding position where I can apply my knowledge and skills, and contribute to the success of the company.
            </p>
          </div>

          <div>
            <h3>Known Technologies</h3>

            <div className='grid-wrapper'>
              <div><img src="/C Sharp Logo.png" alt="C# Logo"/></div>
              <div><img src="/css3-logo.png" alt="CSS3 Logo"/></div>
              <div><img src="/HTML5 Logo.png" alt="HTML5 Logo" /></div>
              <div><img src="/JavaScript-logo.png" alt="JavaScript Logo" /></div>
              <div><img src="/MongoDB Logo.png" alt="MongoDB Logo" /></div>
              <div><img src="/NextJS Logo.png" alt="NextJS Logo" /></div>
              <div><img src="/NodeJS Logo.png" alt="NodeJS Logo"/></div>
              <div><img src="/Python-logo.png" alt="Python Logo"/></div>
              <div><img src="/React Logo.png" alt="React Logo" /></div>
              <div><img src="/TypeScript Logo.png" alt="TypeScript Logo"/></div>
            </div>
          </div>
        </AppearOnScroll>

        <AppearInView>
          <div className='arrow-container' style={{ marginTop: "var(--padding)" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line></svg>
          </div>
        </AppearInView>
      </div>

      <div className='work'>
        <AppearOnScroll>
          <div className='work-container'>
            <h1>Previous Work</h1>

              <div className='work-wrapper'>
                {projects.map((project) => (
                  <Card key={project.name} project={project} />
                ))}
              </div>
          </div>
        </AppearOnScroll>

        <AppearInView>
          <div className='arrow-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line></svg>
          </div>
        </AppearInView>
      </div>

      <div className='contact'>
        <AppearOnScroll>
          <div className='contact-container'>
            <h1>Contact Me</h1>
            <p>I am always open to working with people on projects and always open to possible job offers. Whether you have an idea, a question, or a proposal, I would love to hear from you. You can use the socials below to send me a message and I will get back to you as soon as possible. Don’t hesitate to reach out, I am looking forward to connecting with you.</p>

            <div className='contact-wrapper'>
              {socials.map((social) => (
                <Card key={social.name} project={social} social={true} />
              ))}
            </div>
          </div>
        </AppearOnScroll>
      </div>
    </main>
  )
}