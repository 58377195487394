import { motion } from "framer-motion";

export default function AppearInView({ children }:{children:any}) {

    return (
        <motion.div initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      variants={{
        visible: { opacity: 1, paddingTop: 0 },
        hidden: { opacity: 0, paddingTop: 70 }
      }}
      >
        {children}
      </motion.div>
    )
}