import { useEffect } from 'react';
import './Card.css';

export default function Card({ project, social }: { social?:boolean, project:{ name:string, url:string, imagePath:string }}) {

    useEffect(() => {
        let ele = document.getElementById(project.name + "-image");
        if (!ele) return;
        ele.style.backgroundImage = `url(${project.imagePath})`;
    }, [project.imagePath, project.name]);


    return (
        <div className={!social ? 'card-container' : 'social-container'}>
            {!social && <div id={project.name + "-image"} className='card-image'></div>}

            <div className='card-description'>
                <h3>{project.name}</h3>
                <h5>{project.url}</h5>
            </div>
        </div>
    )
}
